import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import staticLoader from '@utils/staticLoader';
import { m } from 'framer-motion';
import NextImage from "next/image";
import NextLink from 'next/link';
import { ReactElement } from 'react';
import { Page } from '../src/components';
import { MotionContainer, varBounce } from '../src/components/animate';
import Layout from '../src/layouts';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(15, 2.5),
}));

// ----------------------------------------------------------------------

export default function PageNotFound() {
  return (
    <MotionContainer>
      <Page seo={{ metaTitle: '404' }}>
        <RootStyle>
          <Stack alignItems="center" sx={{ maxWidth: 480 }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Side ikke funnet!
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Beklager, vi fant ikke siden du leter etter. Kanskje du har skrevet feil URL?
            </Typography>

            <Box position="relative" sx={{ maxWidth: 320, my: { xs: 6, sm: 4 } }}>
              <NextImage
                alt="404"
                loader={staticLoader}
                src="/static/illustrations/illustration_404.svg"
                width="466px"
                height="466px"
              />
            </Box>
            <NextLink href="/" passHref>
              <Button size="large" variant="contained">
                Til fremsiden
              </Button>
            </NextLink>
          </Stack>
        </RootStyle>
      </Page>
    </MotionContainer >
  );
}

// ----------------------------------------------------------------------

PageNotFound.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout simpleHeader disabledFooter>
      {page}
    </Layout>
  );
};
